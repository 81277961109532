export default {
  provider: 'https://data-seed-prebsc-1-s1.binance.org:8545', // bsc provider
  // 币安测试链ID
  ethChainNumberId: 97,
  // 币安链配置
  chainConfig: {
    chainId: '0x61',
    chainName: 'Smart Chain - Testnet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://testnet.bscscan.com']
  }
}
