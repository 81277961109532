import config from '@/config/app.config'

// 给文件添加域名
export const setUrlPath = (url) => {
  if (!url) { return '' }
  const index = url.indexOf('http')
  const firstChar = url.charAt(0)
  if (index === 0) return url
  if (firstChar === '/') {
    return `${config.imageUrl}${url}`
  }
  if (firstChar !== '/') {
    return `${config.imageUrl}/${url}`
  }
}
